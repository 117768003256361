import React from 'react';
import '../ryanStyle/style.css';
import '../ryanStyle/iframe.css'
import img1 from '../img/pinkney.jpg';
import img2 from '../img/msr.jpg';
import img3 from '../img/tanner.jpg';
import img4 from '../img/intex.jpeg';
import img5 from '../img/msr.jpg';
import img6 from '../img/caleb.png';
import img7 from '../img/millionaire.jpeg';
import img8 from '../img/qualtrics.jpeg'
import img9 from '../img/salesforce.webp'
import img10 from '../img/deloitte.webp'

function Home(){

    return (

        <div class="wrapper">
            <div class="sidebar">
                <div class="sidebar-text d-flex flex-column h-100 justify-content-center text-center"><br></br>
                    <img class="mx-auto d-block w-75 bg-primary img-fluid rounded-circle mb-4 p-3" src={img1} alt="" />
                    <h1 class="font-weight-bold">Ryan Pinkney</h1>
                    <p class="mb-4">
                        I have always been passionate about technology because it is fast moving like me. 
                        I am constantly on the go, and I am quick learner. My focus 
                        is to use technology to solve life's problems, both big and small.
                    </p>
                    <div class="d-flex justify-content-center mb-5">
                        <a class="btn btn-outline-primary mr-2" href="https://twitter.com/ryan_pinkney"><i class="fab fa-twitter"></i></a>
                        <a class="btn btn-outline-primary mr-2" href="https://www.facebook.com/ryan.pinkney.1/"><i class="fab fa-facebook-f"></i></a>
                        <a class="btn btn-outline-primary mr-2" href="https://www.linkedin.com/in/ryanpinkney/"><i class="fab fa-linkedin-in"></i></a>
                        <a class="btn btn-outline-primary mr-2" href="https://www.instagram.com/ryan_mr_pinkney/"><i class="fab fa-instagram"></i></a>
                    </div>
                    <a href="https://linktr.ee/pinkney_mr" class="btn btn-lg btn-block btn-primary mt-auto">More Links</a>
                </div>
                <div class="sidebar-icon d-flex flex-column h-100 justify-content-center text-right">
                    <i class="fas fa-2x fa-angle-double-right text-primary"></i>
                </div>
            </div>
            <div class="content">
             
                <div class="container p-0">
                    <nav class="navbar navbar-expand-lg bg-secondary navbar-dark">
                        <button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse justify-content-between" id="navbarCollapse">
                            <div class="navbar-nav m-auto">
                                <a href="/" class="nav-item nav-link active">Home</a>
                                <a href="about" class="nav-item nav-link">About</a>
                                {/* <a href="port" class="nav-item nav-link">Portfolio</a> */}
                                <a href="more" class="nav-item nav-link">More</a>
                                <a href="contact" class="nav-item nav-link">Contact Me</a>
                            </div>
                        </div>
                    </nav>
                </div>
                <div class="container p-0">
                    <div id="blog-carousel" class="carousel slide" data-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <img class="w-100" src={img2} alt="" height="500" />
                                <div class="carousel-caption d-flex flex-column align-items-center justify-content-center">
                                    <h2 class="mb-3 text-white font-weight-bold">Marriott Student Review</h2>
                                    <div class="d-flex text-white">
                                        <small class="mr-2"><i class="fa fa-folder"></i> Former Managing Editor</small>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <img class="w-100" src={img3} alt="" height="500"/>
                                <div class="carousel-caption d-flex flex-column align-items-center justify-content-center">
                                    <h2 class="text-white font-weight-bold">Marriott School of Business</h2>
                                    <div class="d-flex">
                                        <small class="mr-2"><i class="fa fa-folder"></i> Information Systems</small>
                                    </div>
                                </div>
                            </div>
                             {/* <div class="carousel-item">
                                <img class="w-100" src={img4} alt="" height="560" width="300"/>
                                <div class="carousel-caption d-flex flex-column align-items-center justify-content-center">
                                    <h2 class="text-white font-weight-bold">Intex 3rd Place Winners</h2>
                                    <div class="d-flex">
                                        <small class="mr-2"><i class="fa fa-calendar-alt"></i> 13-Apr-2022</small>
                                        <small class="mr-2"><i class="fa fa-folder"></i> Web Development</small>
                                    </div>
                                </div>
                            </div> */}
                            <div class="carousel-item">
                                <img class="w-100" src={img10} alt="" height="510" width="300"/>
                                <div class="carousel-caption d-flex flex-column align-items-center justify-content-center">
                                    <h2 class="text-white font-weight-bold">Business Technology Solution Analyst</h2>
                                    <div class="d-flex">
                                        <small class="mr-2"><i class="fa fa-calendar-alt"></i>June-2024</small>
                                        <small class="mr-2"><i class="fa fa-folder"></i> Deloitte</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a class="carousel-control-prev" href="#blog-carousel" data-slide="prev">
                            <span class="carousel-control-prev-icon"></span>
                        </a>
                        <a class="carousel-control-next" href="#blog-carousel" data-slide="next">
                            <span class="carousel-control-next-icon"></span>
                        </a>
                    </div>
                </div>
   
                <div class="container bg-white pt-5">

                <div class="row blog-item px-3 pb-5">
                        {/* iframe for featured content from Ryan's channel */}
                        <iframe width="720" height="395" src="https://www.youtube.com/embed/uACeuFfHmd4?si=mgJCuWcUMWqEcfxN" title="YouTube video player" 
                        frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                        allowfullscreen></iframe>
                        <br></br>
                        {/* end iframe */}
                        <p class="mt-4 font-weight-bold bg-white text-center">Ryan Pinkney on YouTube</p>  
                    </div>


                    <div class="row blog-item pb-5">                        
                    <div class="col-md-5">
                        <img class="img-fluid mb-4 mb-md-0" src={img9} alt="" />
                    </div>
                    <div class="col-md-7">
                        <h3 class="mt-md-4 px-md-3 mb-2 py-2 bg-white font-weight-bold">Why Salesforce?</h3>
                        <div class="d-flex mb-3">
                            <small class="mr-2 text-muted"><i class="fa fa-calendar-alt"></i> 2023</small>
                            <small class="mr-2 text-muted"><i class="fa fa-folder"></i> Medium</small>
                        </div>
                        {/* <p>
                            Managing Editor for the BYU Marriott School of Business student journal</p> */}
                        <a class="btn btn-link p-0" href="https://medium.com/@mr_ryan_pinkney/why-salesforce-9613ce6d8970">Read Now <i class="fa fa-angle-right"></i></a>
                    </div>
                    </div>


                    <div class="row blog-item px-3 pb-5"><br></br><br></br>
                        {/* iframe for featured content from Ryan's channel */}
                        <iframe width="720" height="395" src="https://www.youtube.com/embed/6cVSt3kAKak?si=aHyweDcaHuc-amb7" 
                        title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; 
                        gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        <br></br>
                        {/* end iframe */}
                        <p class="mt-4 font-weight-bold bg-white text-center">Ryan Pinkney on YouTube</p>  
                    
                    </div>


                    <div class="row blog-item px-3 pb-5">
                            {/* iframe for the ALA conference video */}
                            <iframe class="text-center" width="720" height="395" src="https://www.youtube.com/embed/B6_7Av21kuc" 
                            title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                            allowfullscreen></iframe> <br></br>
                            {/* end iframe */}
                            <p class="mt-4 font-weight-bold bg-white text-center">American Library Association 2021 Virtual Conference</p>  
                    </div><br></br>
                  
                    <div class="row blog-item px-3 pb-5">
                    <iframe title="Playlist" src="https://open.spotify.com/embed/episode/4UYg2PtcnOm4p2BZl1gREB?utm_source=generator" width="100%" height="232" 
                        frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
                    </div>

                 
                    <div class="row blog-item px-3 pb-5">
                        <div class="col-md-5">
                            <img class="img-fluid mb-4 mb-md-0" src={img6} alt="" />
                        </div>
                        <div class="col-md-7">
                            <h3 class="mt-md-4 px-md-3 mb-2 py-2 bg-white font-weight-bold">Is Caleb Hammer the Next Dave Ramsey?</h3>
                            <div class="d-flex mb-3">
                                <small class="mr-2 text-muted"><i class="fa fa-calendar-alt"></i>2023</small>
                                <small class="mr-2 text-muted"><i class="fa fa-folder">Medium</i></small>
                            </div>
                            <p></p>
                            <a class="btn btn-link p-0" href="https://medium.com/@mr_ryan_pinkney/is-caleb-hammer-the-next-dave-ramsey-499fd9f88cb8">See More <i class="fa fa-angle-right"></i></a>
                        </div>
                    </div><br></br>

                  
                    <div class="row blog-item px-3 pb-5">
                    <iframe className="iframeCustom" title="PlaylistSecond" src="https://open.spotify.com/embed/playlist/1a5EmfJPcH3r4QKP7WaChD?utm_source=generator" 
                        width="100%" height="380" frameBorder="0" 
                        allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
                    </div>

                    <div class="row blog-item px-3 pb-5">
                        <div class="col-md-5">
                            <img class="img-fluid mb-4 mb-md-0" src={img7} alt="" />
                        </div>
                        <div class="col-md-7">
                            <h3 class="mt-md-4 px-md-3 mb-2 py-2 bg-white font-weight-bold">
                            The Millionaire Next Door — Book Review</h3>
                            <div class="d-flex mb-3">
                                <small class="mr-2 text-muted"><i class="fa fa-calendar-alt"></i> 2023</small>
                                <small class="mr-2 text-muted"><i class="fa fa-folder"></i> Medium</small>
                            </div>
                            <p></p>
                            <a class="btn btn-link p-0" href="https://medium.com/@mr_ryan_pinkney/the-millionaire-next-door-book-review-f6d35a27d64c">Read Now<i class="fa fa-angle-right"></i></a><br></br>
                        </div>
                    </div>
                </div>

                <div class="container py-4 bg-secondary text-center">
                    <p class="m-0 text-white">
                        &copy; <a class="text-white font-weight-bold" href="index.html">Ryan Pinkney</a>. All Rights Reserved.
                    </p>
                </div>
            </div>
        </div>


    );
}

export default Home;